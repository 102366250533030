<template>
<div>
    <div class="row">
            <div class="col-md-11">
                <!-- <div class="form-group"> -->
                    <!-- <label for="exampleInputFile">Neue Datei hochladen</label> -->
                    <!-- <div class="input-group">
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" v-model="file">
                            <label class="custom-file-label" for="exampleInputFile">Wähle Datei</label>
                        </div>
                        <div class="input-group-append">
                            <span class="input-group-text bg-primary" @click="saveImage">Speichern</span>
                        </div>
                    </div> -->
                    
                <!-- </div> -->
                <div class="form-group">
                    <!-- <label for="file" class="control-label">Neue Datei hochladen</label> -->
                    <b-form-file size="sm" class="form-control" v-model="form.file" :class="{'is-invalid': form.errors.has('file')}" />
                    <has-error :form="form" field="file"></has-error>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group">
                    <!-- <label for="file" class="control-label">Datei speichern</label> -->
                    <button type="button" class="btn btn-sm btn-primary" @click="saveImage">Upload</button>
                </div>
            </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <template v-if="documents.length >= 1">
            <div class="table-responsive">
                <table class="table table-hover table-sm">
                    <thead>
                        <!-- <th><input type="checkbox" v-model="selectAll" /></th> -->
                        <th>
                            Vorschau
                        </th>
                        <th>
                            Name
                        </th>
                        <th>Mime</th>
                        <th style="width: 140px;">Aktionen</th>                 
                    </thead>
                    <tbody>
                        <tr v-for="document in documents" :key="document.id">
                            <td class="align-middle"><img class="zoom" :src="document.base" @click="downloadDocument(document)"/></td>
                            <td class="align-middle">{{ document.name }}</td>
                            <td class="align-middle">{{ document.mime }}</td>
                            <td class="align-middle">
                                <button class="btn btn-sm btn-secondary mr-1" @click="downloadDocument(document)"><i class="fas fa-fw fa-download"></i></button>
                                <button class="btn btn-sm btn-danger" @click="deleteDocument(document.id)"><i class="fas fa-fw fa-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </template>
            <template v-else>
                <h3>Es liegen noch keine Dokumente in dieser Rubrik</h3>
            </template>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props:[
        'type',
        'dealer',
    ],

    data(){
        return {
            // file: null,
            documents: [],
            form: new window.Form({
                file: null,
                mode: this.type, 
                _method: "POST",
            }),
        }
    },

    methods:{
        downloadDocument(document){
            this.axios
                .get("/dealers/" + this.dealer.id + "/documents/" + document.id + "/download", {responseType: 'arraybuffer'})
                .then((response) => {
                    // console.log(response);
                    //this.orders = response.data.data;
                    // this.downloadFile(response, document)
                    this.downloadFile(response, document);
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        downloadImg(response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "file.jpg"); //or any other extension
            document.body.appendChild(link);
            link.click();
        },

        downloadFile(response, doc){
            var newBlob = new Blob([response.data], {type: doc.mime_type})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = doc.name + '.' + doc.mime
            link.click()
            setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
            }, 100)
        },

        deleteDocument(id){
            this.$swal({
                    title: "Möchtest du das Dokument wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .delete("/dealers/"+ this.dealer.id + "/delete/documents/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Dokument erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.getDocuments();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        saveImage(){
            this.form
                .post("/dealers/"+this.dealer.id + "/upload/documents")
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Dokument wurde gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.getDocuments();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        getDocuments(){
            this.axios
                .get("/dealers/"+this.dealer.id + "/documents/" + this.type)
                .then((response) => {
                    this.documents = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    mounted(){
        this.getDocuments();
    }
}
</script>

<style>
.zoom {
  /* padding: 50px; */
  /* background-color: green; */
  transition: transform .2s; /* Animation */
  width: 125px;
  height: 125px;
  margin: 0 auto;
}

/* .zoom:hover {
  transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) 
  border: 1px solid black;
  z-index: 9999;
  margin-left: 2em;
} */
</style>